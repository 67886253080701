define('ember-add-to-homescreen/components/add-to-homescreen', ['exports', 'ember-add-to-homescreen/templates/components/add-to-homescreen'], function (exports, _addToHomescreen) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: _addToHomescreen.default,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      window.addToHomescreen({ debug: false });
    }
  });
});